<template>
  <div class="security-seal">
    <div v-if="storeCode === 'br'" class="security-seal__br-tags">
      <p v-if="noContent">
        <span class="seal-security__subtitle">
          <sprite-tag tag="selo_100-seguro" />
        </span>
      </p>
      <div v-else class="security-seal__list">
        <div class="security-seal__item">
          <selo-comodo />
        </div>

        <div class="security-seal__item">
          <selo-reclameaqui />
        </div>

        <div class="security-seal__item">
          <a
            v-if="!justTags"
            href="https://eureciclo.com.br/"
            title="Eu reciclo"
            target="_blank"
            rel="noreferrer"
          >
            <selo-eureciclo />
          </a>
        </div>
      </div>
    </div>
    <div v-else class="security-seal__global-tags">
      <p>
        <selo-norton />

        <a
          href="https://www.mcafeesecure.com/verify?host=shop-gocase.com"
          target="_blank"
          rel="nofollow"
        >
          <selo-mcafee />
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SeloComodo from '~/components/icons/selos/comodo/Comodo'
import SeloEureciclo from '~/components/icons/selos/eureciclo/Eureciclo'
import SeloMcafee from '~/components/icons/selos/mcafee/Mcafee'
import SeloNorton from '~/components/icons/selos/norton/Norton'
import SeloReclameaqui from '~/components/icons/selos/reclameaqui/ReclameAqui'
import SpriteTag from '~/components/sprite-tag/SpriteTag.vue'

export default {
  name: 'SecuritySeal',
  components: {
    SpriteTag,
    SeloComodo,
    SeloEureciclo,
    SeloReclameaqui,
    SeloMcafee,
    SeloNorton,
  },
  props: {
    justTags: {
      type: Boolean,
      default: false,
    },
    noContent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['storeCode']),
  },
}
</script>

<style lang="scss" scoped>
.header__right {
  .security-seal {
    margin-top: 25px;
  }
}

.seal-security__subtitle {
  font-size: 12px;
  margin-right: 10px;
  text-transform: uppercase;
}

.security-seal__list {
  margin-left: -6px;
  margin-right: -6px;
}
.security-seal__item {
  width: 25%;
  padding: 0 6px;

  svg {
    max-width: 100%;
    height: auto;
  }
}

.security-seal img,
.security-seal__seal {
  vertical-align: top;
  margin-right: 10px;
}

.security-seal__seal {
  display: inline-block;
  padding-top: 7px;
  padding-bottom: 7px;
}

.security-seal__br-tags {
  display: flex;
  .seal-security__subtitle {
    line-height: 48px;
  }
  .selo_site_seguro_certisign {
    margin-top: 6px;
  }
  .selo-internet-segura {
    margin-top: 7px;
  }
  .selo-ebit-ouro {
    margin-top: 8px;
  }
  .selo-ra1000 {
    margin-top: 3px;
  }
  .comodo-secure {
    margin-top: -5px;
  }
}

.security-seal__global-tags {
  .seal-security__subtitle {
    line-height: 48px;
  }

  .selo_site_seguro_mcafee {
    margin-top: 5px;
  }
}

.security-seal p {
  margin-top: 0;
}

@media (max-width: $screen_large) {
  .security-seal__list {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: $screen_large) {
      justify-content: center;
    }
  }
}
@media (min-width: $screen_large) {
  .security-seal {
    display: block;
  }

  .security-seal__list {
    display: flex;
    margin: 0;
    svg {
      display: block;
    }
  }
  .security-seal__item {
    margin-left: 4 * $v4_gutter;
    padding: 0;
    width: auto;

    &:first-child {
      margin-left: 0;
    }
  }
}
</style>
