<template>
  <div :class="['footer__copyright', { 'footer__copyright--center': center }]">
    <strong class="footer__copyright-title">{{ footerCopyright.copy }}</strong>
    <p class="footer__copyright-text">
      {{ justCopy ? '' : footerCopyright.copyInfo }}
    </p>
    <p
      v-if="!justCopy && footerCopyright.address"
      class="footer__copyright-text"
    >
      {{ footerCopyright.address }}
    </p>
    <p
      v-if="!justCopy && footerCopyright.notice"
      class="no-bottom-margin footer__copyright-text"
    >
      {{ footerCopyright.notice }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'FooterCopyright',
  props: {
    justCopy: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    footerCopyright() {
      const fullYear = new Date().getFullYear()
      const copyright = {
        br: {
          copy: `Copyright © ${fullYear} Gocase. Todos os direitos reservados.`,
          copyInfo:
            'Todo o conteúdo do site, todas as fotos, imagens, logotipos, marcas, layout, aqui veículados são de propriedade exclusiva da GO COMÉRCIO DE ARTIGOS ELETRÔNICOS E ACESSÓRIOS LTDA. É vedada qualquer reprodução, total ou parcial, de qualquer elemento de identidade, sem expressa autorização. A violação de qualquer direito mencionado implicará na responsabilização cível e criminal nos termos da Lei.',
          address:
            'Rodovia Fernão Dias, Km 9745, S/N, Bairro Dos Pires - Extrema/Minas Gerais. Complemento: Condomínio VBI Log, Módulo B1, Galpão G7. CEP: 37640-950. GO COMÉRCIO DE ARTIGOS ELETRÔNICOS E ACESSÓRIOS LTDA 22.165.464/0003-52',
          notice:
            'Os preços dos produtos estão sujeitos a alteração sem aviso prévio.',
        },
        global: {
          copy: `Copyright © ${fullYear} Gocase. All rights reserved.`,
        },
      }
      return copyright[this.$storeCode]
    },
  },
}
</script>

<style lang="scss" scoped>
.footer__copyright--center {
  text-align: center;
}

.footer__copyright-title {
  color: $v4_color_text;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
}
.footer__copyright-text {
  color: $v4_color_text;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.01em;
}
</style>
