<template>
  <ul class="footer__menu">
    <li v-for="(menu, idx) in footerMenu" :key="idx">
      <a
        rel="nofollow"
        :href="isToAdapt(menu.url)"
        @click.prevent="menuRedirect(isToAdapt(menu.url))"
        >{{ menu.name }}</a
      >
    </li>
  </ul>
</template>

<script>
import { mapState } from 'vuex'
import adaptUrl from '~/commun/utils/adaptUrl'
import isExternalLink from '~/utils/isExternalLink'
import positionable from '~/mixins/positionable'

export default {
  name: 'Menu',
  mixins: [positionable],
  props: {
    number: {
      type: Number,
      default: 1,
      validator: (n) => [1, 2].includes(n),
    },
  },
  computed: {
    ...mapState(['siteStore', 'locale']),
    footerMenu() {
      const firstMenu = [
        'quem-somos',
        'onde-encontrar',
        'compre-fora-do-brasil',
        'gocase-reviews',
        '/reviews',
        'about-us',
        '/about-us',
        'student-discount',
        'wholesale',
        'trabalhe-conosco',
        'https://reseller.gocase.com.br/en/welcome',
      ]

      let footerMenu = this.positioning(this.siteStore.footer_menu)

      footerMenu = footerMenu.filter((fm) =>
        this.number === 1
          ? firstMenu.includes(fm.name_slug) || firstMenu.includes(fm.url)
          : !firstMenu.includes(fm.name_slug) && !firstMenu.includes(fm.url)
      )

      return footerMenu
    },
  },
  methods: {
    adaptUrl,
    isToAdapt(url) {
      return ['https://www.gocase.com', 'https://www.gocase.com/'].includes(url)
        ? url
        : adaptUrl(url)
    },
    menuRedirect(adaptedUrl) {
      if (adaptedUrl === '/reviews-gocase') {
        window.open(adaptedUrl, '_self')
      } else if (isExternalLink(adaptedUrl)) {
        window.open(adaptedUrl, '_blank')
      } else {
        this.$router.push(adaptedUrl)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.footer__menu {
  list-style-type: none;
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  text-align: left;
}

.footer__menu li {
  display: inline-block;
  line-height: 17px;
}

.footer__menu a {
  text-decoration: none;
  display: inline-block;
  text-decoration: none;

  &:hover {
    color: #333;
  }
}

@media (min-width: $screen_large) {
  .footer__menu {
    padding: 0;
  }

  .footer__menu li {
    margin-bottom: 10px;
  }

  .footer__menu li {
    display: block;
  }
}

@media (max-width: $screen_large) {
  .footer__menu {
    li {
      display: block;
      text-align: left;
      margin-bottom: 3px;

      a {
        margin: 0;
        display: block;
      }
    }
  }
}
</style>
