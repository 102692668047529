import { SOCIAL } from '~/config'

export default {
  computed: {
    social() {
      const storeCode = this.$store.state.storeCode
      return SOCIAL[storeCode]
    },
  },
}
