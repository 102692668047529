<template>
  <div
    :class="[
      'footer-newsletter',
      { 'footer-newsletter--cart': $route.name === 'cart' },
    ]"
  >
    <h4 class="footer-newsletter__title">
      {{ $t('newsletter.footer_title') }}
    </h4>
    <p class="footer-newsletter__subtitle">
      {{ $t('newsletter.footer_subtitle') }}
    </p>
    <form
      id="newsletter_subscriber"
      class="footer-newsletter__form"
      accept-charset="UTF-8"
      @submit="onSubmit"
    >
      <input
        id="newsletter_email"
        v-model="email"
        type="text"
        class="footer-newsletter__input"
        name="email"
        :placeholder="$t('newsletter.email_placeholder')"
        aria-label="Email"
      />

      <v-button
        class="footer-newsletter__btn"
        height="44px"
        :radius="false"
        :loading="submiting"
        type="submit"
        :aria-label="$t('submit')"
      >
        <arrowRight class="footer-newsletter__arrow" />
      </v-button>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import isBlank from '~/utils/isBlank'
import validateEmail from '~/commun/utils/validateEmail'
import appendUrlParam from '~/utils/appendUrlParam'
import VButton from '~/components/v-button/VButton.vue'
import errorHandler from '~/mixins/error-handler'
import pushDataLayer from '~/mixins/gtm/push-data-layer'
import OmetriaEmail from '~/utils/OmetriaEmail'
import ArrowRight from '~/components/icons/arrow-right/ArrowRight'

export default {
  name: 'Newsletter',
  components: {
    VButton,
    ArrowRight,
  },
  mixins: [errorHandler, pushDataLayer],
  data() {
    return {
      email: '',
      submiting: false,
      errors: {
        email: [],
      },
    }
  },
  computed: {
    ...mapState(['storeCode', 'locale', 'store']),
  },
  methods: {
    async onSubmit(e) {
      if (this.submiting) return
      e.preventDefault()

      if (this.validateInputs()) {
        this.submiting = true

        try {
          const params = {
            ...this.$store.getters.storeInfo,
            email: this.email,
          }

          await this.$axios.$post(
            appendUrlParam('/newsletter_subscribers/subscribe', params)
          )

          OmetriaEmail.set(this.email)

          this.pushDataLayer({
            event: 'genericEvent',
            eventCategory: 'email',
            eventAction: 'captureEmail',
            eventValue: this.email,
          })

          this.$displayTopMessage(this.$t('newsletter.subscribe_success'))
        } catch (err) {
          this.handleError(err)
        } finally {
          this.submiting = false
        }
      } else {
        this.displayErrors()
      }
    },
    validateInputs() {
      this.errors.email = []

      if (isBlank(this.email)) {
        this.errors.email.push(this.$t('newsletter.errors.required_email'))
      } else if (!validateEmail(this.email)) {
        this.errors.email.push(
          this.$t('newsletter.errors.invalid_email', {
            email: this.email,
          })
        )
      }

      return this.errors.email.length === 0
    },
    displayErrors() {
      this.errors.email.forEach((err) => {
        this.$displayTopMessage(err, {
          type: 'error',
        })
      })
    },
  },
}
</script>

<style lang="scss">
.footer-newsletter {
  margin: 0 0 35px;

  &--cart {
    display: none;
  }

  .footer-newsletter__title {
    font-size: 18px;
  }
  .footer-newsletter__subtitle {
    margin-top: 5px;
    color: lighten($v4_color_text, 10%);
    font-size: 13px;
  }

  .footer-newsletter__form {
    display: flex;
  }

  .footer-newsletter__input,
  .footer-newsletter__btn {
    border: 0;
    display: block;
    height: 44px;
    transition: all 200ms ease-in-out;
  }

  .footer-newsletter__input {
    border: 1px solid $v4_color_text;
    flex-grow: 1;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-left: 15px;
    padding-right: 15px;

    &:focus {
      box-shadow: inset 0 0 0 1px $v4_color_text;
      outline: 0;
    }
  }

  button.footer-newsletter__btn.v-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    width: 46px;

    .v-button--loading {
      transform: scale(0.65);
    }
  }

  .footer-newsletter__arrow {
    fill: white;
    height: 13px;
    width: auto;
  }
}

@media (min-width: $screen_large) {
  .footer-newsletter {
    margin-left: 0;
    margin-right: 45px;
    margin-bottom: 35px;

    .footer-newsletter__title {
      margin-top: -5px;
    }
  }
}
</style>
