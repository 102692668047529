<template>
  <ul class="footer__payments">
    <li class="footer__payments__item">
      <visa-icon />
    </li>
    <li class="footer__payments__item">
      <mastercard-icon />
    </li>
    <li v-if="$isBrStore" class="footer__payments__item">
      <elo-icon />
    </li>
    <li v-if="$isBrStore" class="footer__payments__item">
      <dinners-icon />
    </li>
    <li class="footer__payments__item">
      <american-icon />
    </li>
    <li v-if="$isBrStore" class="footer__payments__item">
      <hipercard-icon />
    </li>
    <li v-if="$isBrStore" class="footer__payments__item">
      <aura-icon />
    </li>
    <li v-if="$isGlobalStore" class="footer__payments__item">
      <maestro-icon />
    </li>
    <li v-if="$isGlobalStore" class="footer__payments__item">
      <paypal-icon />
    </li>
    <li v-if="$isGlobalStore" class="footer__payments__item">
      <ideal-icon />
    </li>
    <li v-if="$isGlobalStore" class="footer__payments__item">
      <klarna-icon />
    </li>
  </ul>
</template>

<script>
import VisaIcon from '~/components/icons/payments/visa/Visa'
import MastercardIcon from '~/components/icons/payments/mastercard/Mastercard'
import EloIcon from '~/components/icons/payments/elo/Elo'
import DinnersIcon from '~/components/icons/payments/dinners/Dinners'
import AmericanIcon from '~/components/icons/payments/american/American'
import HipercardIcon from '~/components/icons/payments/hipercard/Hipercard'
import AuraIcon from '~/components/icons/payments/aura/Aura'
import MaestroIcon from '~/components/icons/payments/maestro/Maestro'
import PaypalIcon from '~/components/icons/payments/paypal/Paypal'
import IdealIcon from '~/components/icons/payments/ideal/Ideal'
import KlarnaIcon from '~/components/icons/payments/klarna/Klarna'

export default {
  name: 'FooterPayments',
  components: {
    VisaIcon,
    MastercardIcon,
    EloIcon,
    DinnersIcon,
    AmericanIcon,
    HipercardIcon,
    AuraIcon,
    MaestroIcon,
    PaypalIcon,
    KlarnaIcon,
    IdealIcon,
  },
}
</script>

<style lang="scss" scoped>
.footer__payments {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -4 * $v4_gutter;

  .footer__payments__item {
    margin-right: 4 * $v4_gutter;
    margin-bottom: 4 * $v4_gutter;

    svg {
      display: block;
    }
  }
}
</style>
