import isPresent from '~/utils/isPresent'

export default {
  data() {
    return {
      errors: [],
    }
  },
  methods: {
    handleError(err, showStringError = false) {
      const { response } = err

      const details = response?.data?.details || response?.data?.error

      if (response?.status === 400 && isPresent(details)) {
        this.displayErrors(details, showStringError)
      } else {
        this.$displayTopMessage(this.$t('action_error'), {
          type: 'error',
        })
      }
    },
    displayErrors(details, showStringError) {
      this.errors = []
      const errors = []

      if (Array.isArray(details)) {
        errors.push(...details)
      } else if (typeof details === 'object') {
        for (const detail in details) {
          errors.push(
            `${detail.split('_').join(' ').toUpperCase()}: ${details[detail]}`
          )
        }
      } else if (typeof details === 'string') {
        errors.push(details)

        if (showStringError) {
          this.$displayTopMessage(errors[0], {
            type: 'error',
          })
        }
      }

      this.errors = errors
    },
  },
}
