<template>
  <svg
    width="116"
    height="72"
    viewBox="0 0 116 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.43636 0.5H107.564C111.939 0.5 115.5 3.99068 115.5 8.22857V63.7714C115.5 68.0093 111.939 71.5 107.564 71.5H8.43636C4.06068 71.5 0.5 68.0093 0.5 63.7714V8.22857C0.5 3.99068 4.06068 0.5 8.43636 0.5Z"
      fill="white"
      stroke="#F5F6F5"
    />
    <path
      d="M57.9485 38.1775L55.643 36.8189L55.4784 37.0865C54.9637 37.9099 54.2433 38.3216 53.3581 38.3216C51.8349 38.3216 50.7027 37.1689 50.7027 35.6662C50.7027 34.1429 51.8554 33.0107 53.3581 33.0107C54.2639 33.0107 54.9226 33.4018 55.4784 34.2458L55.643 34.5134L57.9485 33.1548L57.7427 32.8666C56.6311 31.261 55.2108 30.4994 53.3375 30.4994C49.8175 30.4994 47.9237 33.1754 47.9237 35.6868C47.9237 38.1981 49.8175 40.8741 53.3375 40.8741C55.1284 40.8741 56.8164 39.9684 57.7427 38.4863L57.9485 38.1775Z"
      fill="#555555"
    />
    <path
      d="M57.825 40.833H60.9951L61.942 38.4864H66.6971L67.644 40.833H70.8346L65.1532 27.103H62.3125L63.0742 28.935L57.825 40.833ZM64.3504 32.5168L65.6267 35.7486H63.033L64.3504 32.5168Z"
      fill="#555555"
    />
    <path
      d="M72.152 40.8536H74.931V34.6782H76.7425V32.0845H74.931V30.87C74.931 30.3348 75.2809 29.779 75.9396 29.779C76.2896 29.779 76.516 29.8407 76.7219 29.9231L77.0306 30.0466L78.1834 27.5146L77.8746 27.4117C77.36 27.2059 76.6395 27.1235 76.1249 27.1235C74.8692 27.1235 73.8194 27.5352 73.1195 28.3174C72.502 28.9968 72.152 29.9436 72.152 30.9935V32.105H70.917V34.6987H72.152V40.8536Z"
      fill="#555555"
    />
    <path
      d="M43.7039 32.9285V40.833H46.7298V27.0206L40.7808 31.5081L34.8112 27.0206V40.8124L37.8372 40.833V32.9285L40.7808 35.1105L43.7039 32.9285Z"
      fill="#555555"
    />
    <path
      d="M83.0208 30.4994C79.5214 30.4994 77.6276 33.1548 77.6276 35.6662C77.6276 38.1775 79.5214 40.833 83.0208 40.833C84.6264 40.8123 86.1291 40.0919 87.3024 38.7539L85.0381 37.5188C84.4617 38.1158 83.7413 38.4245 82.9591 38.4245C81.8063 38.4245 80.7977 37.6629 80.53 36.6542H88.1053V35.9749C88.1259 32.2079 85.141 30.52 83.0208 30.4994ZM80.5918 34.4105C80.9623 33.4636 81.7445 32.9284 82.7532 32.9284C83.7824 32.9284 84.5852 33.4636 84.9969 34.4311L80.5918 34.4105Z"
      fill="#555555"
    />
    <path
      d="M94.4042 30.52C90.9048 30.52 89.011 33.1755 89.011 35.6868C89.011 38.1982 90.9048 40.8536 94.4042 40.8536C96.0098 40.833 97.5125 40.1125 98.6859 38.7745L96.4215 37.5394C95.8452 38.1364 95.1247 38.4452 94.3425 38.4452C93.1897 38.4452 92.1811 37.6835 91.9135 36.6749H99.4887V35.9956C99.5093 32.2286 96.5245 30.52 94.4042 30.52ZM91.9752 34.4105C92.3458 33.4636 93.128 32.9079 94.1366 32.9284C95.1659 32.9284 95.9687 33.4636 96.3804 34.4311L91.9752 34.4105Z"
      fill="#555555"
    />
    <path
      d="M99.1387 30.0466H100.312V30.273H99.8386V31.5081H99.5916V30.273H99.1387V30.0466Z"
      fill="#555555"
    />
    <path
      d="M100.497 30.0466H100.847L101.259 31.1787L101.65 30.0466H102V31.5081H101.753V30.3759L101.341 31.5081H101.135L100.724 30.3759V31.5081H100.477V30.0466H100.497Z"
      fill="#555555"
    />
    <path
      d="M21.4723 30.4582L14 27V40.7506L21.4723 44.2089V40.833L17.0671 38.7951V31.7963L21.4723 33.8342V30.4582Z"
      fill="#555555"
    />
    <path
      d="M21.4723 30.4582L28.9652 27V40.7506L21.4723 44.2089V40.833L25.898 38.7951V31.7963L21.4723 33.8342V30.4582Z"
      fill="#222222"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style></style>
