<template>
  <div class="footer__social">
    <ul class="footer__social-links">
      <li v-for="(value, key) in social" :key="key">
        <a
          :href="`https://www.${key}.com/${value}`"
          rel="noopener"
          target="_blank"
          class="footer__social-icons foo"
          aria-label="Social Icon"
        >
          <facebook-icon v-if="key === 'facebook'" />
          <pinterest-icon v-if="key === 'pinterest'" />
          <twitter-icon v-if="key === 'twitter'" />
          <instagram-icon v-if="key === 'instagram'" />
          <tiktok-icon v-if="key === 'tiktok'" />
          <i
            v-else
            :class="`social_${key}_circle footer__social-img--${key}-circle`"
          ></i>
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
import sociable from '~/mixins/sociable'
import PinterestIcon from '~/components/icons/social/pinterest/Pinterest'
import TwitterIcon from '~/components/icons/social/twitter/Twitter'
import FacebookIcon from '~/components/icons/social/facebook/Facebook'
import InstagramIcon from '~/components/icons/social/instagram/Instagram'
import TiktokIcon from '~/components/icons/social/tiktok/Tiktok'

export default {
  name: 'FooterSocial',
  components: {
    PinterestIcon,
    TwitterIcon,
    FacebookIcon,
    InstagramIcon,
    TiktokIcon,
  },
  mixins: [sociable],
}
</script>

<style lang="scss" scoped>
.footer__social-links {
  list-style-type: none;
  margin-bottom: 22px;
  padding: 0;
  font-size: 36px;
  display: flex;
  justify-content: center;

  svg {
    display: block;
  }

  .footer__social-icons {
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      svg {
        opacity: 0.8;
      }
    }
  }
}

.footer__social-links li {
  display: inline-block;
  margin-left: 0;
  margin-right: 22px;

  &:last-child {
    margin-right: 0;
  }
}

.footer__social-links a {
  text-decoration: none;
  display: flex;

  &:hover {
    color: #333;
  }
}

@media (min-width: $screen_large) {
  .footer__social-links {
    text-align: left;
    justify-content: flex-start;
    margin-bottom: 0;
  }
}
</style>
