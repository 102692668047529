<template>
  <svg
    width="55"
    height="35"
    viewBox="0 0 55 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M51 34.3H3.99998C2.07613 34.3 0.499985 32.7239 0.499985 30.8V4C0.499985 2.07614 2.07613 0.5 3.99998 0.5H51C52.9238 0.5 54.5 2.07614 54.5 4V30.9C54.5 32.7157 52.9321 34.3 51 34.3Z"
        fill="white"
        stroke="#F5F6F5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.66492 14.3953H12.535C14.076 14.3953 14.6561 15.1754 14.5665 16.3215C14.4183 18.2137 13.2744 19.2605 11.7571 19.2605H10.9911C10.7829 19.2605 10.6429 19.3983 10.5866 19.7717L10.2613 21.9424C10.2398 22.0832 10.1657 22.1647 10.0546 22.1758H8.25135C8.0817 22.1758 8.02169 22.0462 8.06614 21.7654L9.16558 14.8064C9.20855 14.5279 9.36117 14.3953 9.66492 14.3953Z"
        fill="#999999"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.1255 14.2664C23.0938 14.2664 23.9873 14.7916 23.865 16.1007C23.7169 17.6565 22.8834 18.5174 21.5684 18.5211H20.4193C20.2541 18.5211 20.1741 18.656 20.1311 18.9323L19.9088 20.3451C19.8755 20.5585 19.7658 20.6637 19.6043 20.6637H18.5353C18.3649 20.6637 18.3056 20.5548 18.3434 20.3111L19.2258 14.6486C19.2695 14.3701 19.3739 14.2664 19.5643 14.2664H22.1233H22.1255ZM20.3845 17.298H21.255C21.7995 17.2772 22.1611 16.9001 22.1974 16.22C22.2196 15.7999 21.9358 15.4992 21.4847 15.5014L20.6653 15.5051L20.3845 17.298ZM26.7715 20.2303C26.8692 20.1414 26.9685 20.0955 26.9544 20.2051L26.9196 20.4674C26.9018 20.6044 26.9559 20.677 27.0834 20.677H28.0331C28.1932 20.677 28.271 20.6126 28.3102 20.3651L28.8955 16.6919C28.9251 16.5075 28.8799 16.4171 28.7399 16.4171H27.6953C27.6012 16.4171 27.5553 16.4697 27.5308 16.6134L27.4923 16.8394C27.4723 16.9572 27.4182 16.9779 27.3678 16.8594C27.1908 16.44 26.7389 16.2519 26.1084 16.2667C24.6437 16.2971 23.6561 17.4091 23.5502 18.8345C23.4687 19.9369 24.2584 20.803 25.3001 20.803C26.0558 20.803 26.3936 20.5807 26.7744 20.2325L26.7715 20.2303ZM25.9758 19.665C25.3453 19.665 24.906 19.162 24.9971 18.5456C25.0882 17.9292 25.6779 17.4261 26.3084 17.4261C26.9389 17.4261 27.3782 17.9292 27.2871 18.5456C27.196 19.162 26.607 19.665 25.9758 19.665ZM30.7543 16.4052H29.7912C29.5927 16.4052 29.5119 16.5534 29.5749 16.7357L30.7706 20.237L29.5978 21.9032C29.4993 22.0425 29.5756 22.1691 29.7142 22.1691H30.7966C30.8596 22.1764 30.9235 22.1654 30.9805 22.1375C31.0375 22.1095 31.0853 22.0657 31.1181 22.0113L34.7957 16.7364C34.9091 16.5741 34.8557 16.4037 34.6698 16.4037H33.6452C33.4696 16.4037 33.3992 16.4734 33.2985 16.6193L31.7649 18.8419L31.0796 16.6142C31.0396 16.4793 30.9395 16.4052 30.7551 16.4052H30.7543Z"
        fill="#555555"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M38.6215 14.2663C39.5899 14.2663 40.4833 14.7915 40.3611 16.1006C40.2129 17.6564 39.3795 18.5173 38.0644 18.521H36.9161C36.7509 18.521 36.6709 18.6559 36.6279 18.9322L36.4056 20.345C36.3723 20.5584 36.2626 20.6636 36.1011 20.6636H35.0321C34.8617 20.6636 34.8024 20.5547 34.8402 20.311L35.724 14.6471C35.7677 14.3685 35.8722 14.2648 36.0626 14.2648H38.6215V14.2663ZM36.8805 17.2979H37.751C38.2956 17.2771 38.6571 16.9 38.6934 16.2199C38.7156 15.7998 38.4319 15.499 37.9807 15.5013L37.1613 15.505L36.8805 17.2979ZM43.2675 20.2302C43.3653 20.1413 43.4646 20.0954 43.4505 20.205L43.4157 20.4673C43.3979 20.6043 43.452 20.6769 43.5794 20.6769H44.5292C44.6892 20.6769 44.767 20.6125 44.8063 20.365L45.3916 16.6918C45.4212 16.5074 45.376 16.417 45.236 16.417H44.1928C44.0987 16.417 44.0528 16.4696 44.0284 16.6133L43.9898 16.8393C43.9698 16.9571 43.9158 16.9778 43.8654 16.8593C43.6883 16.4399 43.2364 16.2518 42.6059 16.2666C41.1412 16.297 40.1537 17.409 40.0477 18.8344C39.9662 19.9368 40.756 20.8029 41.7976 20.8029C42.5533 20.8029 42.8911 20.5806 43.2719 20.2324L43.2675 20.2302ZM42.4726 19.6649C41.8421 19.6649 41.4027 19.1619 41.4939 18.5455C41.585 17.9291 42.1747 17.426 42.8052 17.426C43.4357 17.426 43.875 17.9291 43.7839 18.5455C43.6928 19.1619 43.103 19.6649 42.4726 19.6649ZM46.8533 20.6829H45.7568C45.7377 20.6837 45.7187 20.6804 45.7011 20.673C45.6835 20.6656 45.6677 20.6545 45.6549 20.6403C45.6421 20.6262 45.6325 20.6094 45.627 20.5911C45.6214 20.5729 45.62 20.5536 45.6227 20.5347L46.5858 14.4329C46.595 14.3913 46.618 14.354 46.6511 14.3271C46.6842 14.3002 46.7254 14.2853 46.7681 14.2848H47.8646C47.8836 14.2839 47.9026 14.2873 47.9203 14.2946C47.9379 14.302 47.9537 14.3131 47.9665 14.3273C47.9793 14.3415 47.9888 14.3583 47.9944 14.3765C47.9999 14.3948 48.0014 14.4141 47.9987 14.4329L47.0355 20.5347C47.0266 20.5766 47.0037 20.6143 46.9706 20.6415C46.9375 20.6686 46.8961 20.6838 46.8533 20.6843V20.6829Z"
        fill="#999999"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.86981 12H10.7429C11.5519 12 12.5121 12.0259 13.1536 12.5927C13.5826 12.9713 13.8078 13.5736 13.756 14.2226C13.5796 16.4163 12.2676 17.6454 10.5073 17.6454H9.09075C8.84923 17.6454 8.68995 17.8054 8.62179 18.2381L8.22617 20.757C8.20024 20.92 8.12986 21.0163 8.00391 21.0282H6.23102C6.0347 21.0282 5.96505 20.88 6.01617 20.5525L7.29046 12.4786C7.34158 12.1541 7.52013 12 7.86981 12Z"
        fill="#555555"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.66328 17.9825L9.16484 14.8065C9.20855 14.5279 9.36117 14.3945 9.66492 14.3945H12.535C13.0099 14.3945 13.3944 14.4686 13.6952 14.6057C13.407 16.5586 12.1439 17.6432 10.4902 17.6432H9.07594C8.88628 17.644 8.74699 17.7388 8.66328 17.9825Z"
        fill="#777777"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="55" height="35" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {}
</script>

<style></style>
