<template>
  <footer :class="['footer', { 'footer--minimal': isNoContent }]">
    <div class="container footer__container">
      <div v-if="!isNoContent" class="footer__row footer__row--main">
        <div class="footer__block footer__block--newsletter">
          <footer-newsletter />
          <footer-social />
        </div>

        <div class="footer__block footer__block--nav">
          <span class="footer__subtitle">{{ $t('footer.about_gocase') }}</span>
          <footer-menu :number="1" />
        </div>

        <div class="footer__block footer__block--nav">
          <span class="footer__subtitle">{{ $t('footer.need_help') }}</span>
          <footer-menu :number="2" />
        </div>

        <div class="footer__block footer__block--payments">
          <div>
            <span class="footer__subtitle">{{
              $t('footer.payment_methods')
            }}</span>
            <footer-payments />
          </div>
        </div>
      </div>

      <div v-if="!isNoContent" class="footer__row footer__row--bottom">
        <div
          v-if="$isGlobalStore && !$store.state.isUsStore"
          class="footer__block footer__block--settings"
        >
          <span class="footer__subtitle">{{ $t('footer.settings') }}</span>
          <footer-settings flex-start />
        </div>

        <div v-if="$isBrStore" class="footer__block footer__block--copyright">
          <footer-copyright />
        </div>

        <div class="footer__block footer__block--security">
          <span class="footer__subtitle">{{ $t('footer.security') }}</span>
          <security-seal />
        </div>
      </div>

      <div
        v-if="$isGlobalStore && !isNoContent"
        class="footer__block footer__block--copyright"
      >
        <footer-copyright center />
      </div>

      <div
        v-if="isNoContent"
        :class="[
          'footer__row',
          'footer__row--minimal',
          { 'footer__row--minimal-2': $isGlobalStore },
        ]"
      >
        <div class="footer__block footer__block--payments">
          <div>
            <span class="footer__subtitle">{{
              $t('footer.payment_methods')
            }}</span>
            <footer-payments />
          </div>
        </div>
        <div class="footer__block footer__block--security">
          <span class="footer__subtitle">{{ $t('footer.security') }}</span>
          <security-seal />
          <footer-settings
            v-if="$isGlobalStore && !$store.state.isUsStore && !isNoContent"
          />
        </div>
      </div>
      <div v-if="isNoContent" class="footer__contact">
        <span>{{ $t('footer.need_help') }}</span>
        <a
          rel="nofollow"
          :href="helpCenterUrl"
          :title="$t('footer.contactus')"
          >{{ $t('footer.contactus') }}</a
        >
        <div class="footer__contact-copy">
          {{ footerMinimalCopyright.copy }}
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import FooterSettings from './footer-settings/FooterSettings.vue'
import FooterCopyright from './footer-copyright/FooterCopyright.vue'
import FooterMenu from './footer-menu/FooterMenu.vue'
import FooterPayments from './footer-payments/FooterPayments.vue'
import FooterSocial from './footer-social/FooterSocial.vue'
import FooterNewsletter from './footer-newsletter/FooterNewsletter.vue'
import SecuritySeal from '~/components/security-seal/SecuritySeal.vue'

export default {
  name: 'Footer',
  components: {
    SecuritySeal,
    FooterSettings,
    FooterCopyright,
    FooterMenu,
    FooterPayments,
    FooterSocial,
    FooterNewsletter,
  },
  props: {
    noContent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    footerMinimalCopyright() {
      const fullYear = new Date().getFullYear()
      const copyright = {
        br: {
          copy: `Copyright © ${fullYear} Gocase.`,
        },
        global: {
          copy: `Copyright © ${fullYear} Gocase.`,
        },
      }

      return copyright[this.$storeCode]
    },
    helpCenterUrl() {
      return this.$isBrStore
        ? 'https://www.gocase.com.br/central-de-ajuda'
        : 'https://support.shop-gocase.com/hc/en-us'
    },
    isNoContent() {
      return this.$route.name === 'cart' || this.noContent
    },
  },
}
</script>

<style lang="scss">
.footer {
  background-color: #fafafb;

  &.footer--minimal {
    border-top: 1px solid $v4_color_gray;
    background-color: white;
  }

  .footer__container {
    padding-left: 0;
    padding-right: 0;
  }

  .footer__block {
    padding: 30px 15px;
    border-bottom: 1px solid $v4_color_gray;
  }

  .footer__subtitle {
    display: block;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .footer__block--nav {
    .footer__menu {
      margin: 0;
    }

    li {
      margin-top: 15px;

      a {
        color: $v4_color_text;
      }
    }
  }

  &.footer--minimal {
    padding: 0 0 130px; /* Compensating for the cart overlay */

    .footer__contact {
      margin-top: 20px;
      text-align: center;

      a {
        color: $v4_color_text;
      }
    }
    .footer__contact-copy {
      font-size: 12px;
      margin-top: 10px;
    }
  }

  @media (min-width: $screen_large) {
    padding: 20px 0;

    .footer__container {
      margin-left: auto;
      margin-right: auto;
    }

    .footer__block {
      padding-left: 0;
      padding-right: 0;
      border-bottom: 0;
    }

    .footer__row {
      display: grid;
      grid-gap: 40px;

      &.footer__row--main {
        grid-template-columns: 2fr 1fr 1.4fr 270px;
      }

      &.footer__row--bottom {
        margin-top: 25px;
        padding-top: 15px;
        border-top: 1px solid $v4_color_gray;
        grid-gap: 80px;
        grid-template-columns: 2fr 540px;

        .footer__block--settings {
          order: 2;
        }

        .footer__block--copyright {
          order: 1;
        }
        .footer__block--security {
          order: 1;
        }
      }
    }

    &.footer--minimal {
      padding: 30px 0 50px;

      .footer__row--minimal {
        margin: 0 auto;
        display: grid;
        grid-gap: 80px;
        grid-template-columns: 270px 512px;
        width: 880px;
      }

      .footer__row--minimal-2 {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        > div {
          max-width: 270px;
        }
      }
    }
  }
}
</style>
