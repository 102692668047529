<template>
  <svg
    width="116"
    height="72"
    viewBox="0 0 116 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.43636 0.5H107.564C111.939 0.5 115.5 3.99068 115.5 8.22857V63.7714C115.5 68.0093 111.939 71.5 107.564 71.5H8.43636C4.06068 71.5 0.5 68.0093 0.5 63.7714V8.22857C0.5 3.99068 4.06068 0.5 8.43636 0.5Z"
      fill="white"
      stroke="#F5F6F5"
    />
    <path
      d="M86.6626 37.7043C85.2482 37.7043 84.0936 36.5497 84.0936 35.1354C84.0936 33.7066 85.2482 32.5664 86.6626 32.5664C88.077 32.5664 89.2315 33.721 89.2315 35.1354C89.246 36.5497 88.0914 37.7043 86.6626 37.7043ZM86.6626 30.1129C83.8916 30.1129 81.6401 32.3644 81.6401 35.1354C81.6401 37.9064 83.8916 40.1578 86.6626 40.1578C89.4336 40.1578 91.685 37.9064 91.685 35.1354C91.685 32.3499 89.4336 30.1129 86.6626 30.1129Z"
      fill="#555555"
    />
    <path
      d="M63.5997 37.7043C62.1854 37.7043 61.0308 36.5497 61.0308 35.1354C61.0308 33.7066 62.1854 32.5664 63.5997 32.5664C65.0141 32.5664 66.1687 33.721 66.1687 35.1354C66.1687 36.5497 65.0141 37.7043 63.5997 37.7043ZM63.5997 30.1129C60.8287 30.1129 58.5773 32.3644 58.5773 35.1354C58.5773 37.9064 60.8287 40.1578 63.5997 40.1578C66.3707 40.1578 68.6222 37.9064 68.6222 35.1354C68.6077 32.3499 66.3707 30.1129 63.5997 30.1129Z"
      fill="#555555"
    />
    <path
      d="M81.3227 37.8486C81.0051 37.9352 80.5144 38.0363 80.3413 38.0363C79.4753 38.0363 79.3021 37.7332 79.3021 36.925V32.2778H81.3515V30.3438H79.3021V27.6017H76.993L76.5023 30.3438H74.9003H74.7704C73.457 30.3438 72.4612 31.152 71.8262 32.4076L71.0324 30.3438H69.3727V39.9702H72.2014V35.7848C72.2014 33.6777 73.457 32.3211 74.9291 32.2778H76.4734V37.0693C76.4734 39.3207 77.628 40.1578 79.5042 40.1578C80.4711 40.1578 81.2216 39.9558 81.669 39.8114L81.3227 37.8486Z"
      fill="#555555"
    />
    <path
      d="M95.12 33.3746V39.9702H92.3634V30.3294H94.0376L94.6004 31.8015"
      fill="#555555"
    />
    <path
      d="M94.5859 31.8159C95.423 30.849 96.5632 30.1129 97.9054 30.1129C100.2 30.1129 101.138 31.9458 101.138 33.6344V39.9702H98.3095V33.721C98.3095 32.8984 97.6167 32.451 96.8807 32.451C96.0869 32.451 95.5818 32.9128 95.1344 33.3891"
      fill="#555555"
    />
    <path
      d="M47.5943 26.8512H50.755L55.0847 35.0921V26.8512H57.6969V39.9702H54.8393L50.2066 30.8922V39.9702H47.5943V26.8512Z"
      fill="#555555"
    />
    <path
      d="M101.138 30.3438V30.5314H101.398V31.4407H101.615V30.5314H101.889V30.3438H101.138Z"
      fill="#555555"
    />
    <path
      d="M102.711 30.3438L102.524 31.0654L102.322 30.3438H102.033V31.4407H102.221V30.5747L102.452 31.4407H102.596L102.827 30.5747V31.4407H103V30.3438H102.711Z"
      fill="#555555"
    />
    <path
      d="M51.0148 47.7059C51.0148 46.8689 50.755 45.8875 49.8025 45.8875C49.3551 45.8875 48.951 46.0895 48.5901 46.4648V49.2213C48.9221 49.5821 49.2252 49.7698 49.687 49.7698C50.5385 49.7553 51.0148 49.0193 51.0148 47.7059ZM48.4025 49.7553L48.2005 50.3759H47.6809V43.1742H48.5901V45.8009C48.8932 45.4689 49.3839 45.1226 50.0045 45.1226C51.3756 45.1226 51.9817 46.4792 51.9817 47.7059C51.9817 49.3512 51.1302 50.4625 49.8602 50.4625C49.1963 50.4625 48.7489 50.1883 48.4025 49.7553Z"
      fill="#AAAAAA"
    />
    <path
      d="M52.559 45.2235H53.4827C53.4827 45.2235 54.7816 49.5676 54.7816 49.5821L55.9939 45.2235H56.8887L55.1568 50.7655C54.8538 51.7181 54.3486 52.2521 53.3528 52.2521C53.1507 52.2521 52.9343 52.2232 52.7322 52.1655L52.8477 51.516C52.9776 51.5304 53.1074 51.5737 53.2806 51.5737C53.8146 51.5737 54.2187 51.3284 54.4208 50.6934L52.559 45.2235Z"
      fill="#AAAAAA"
    />
    <path
      d="M59.5875 49.6254L60.0349 48.9904C60.4823 49.3223 61.1174 49.6831 62.0699 49.6831C63.008 49.6831 63.5564 49.2357 63.5564 48.4564C63.5564 47.5904 62.8925 47.3162 61.94 46.9843C60.9153 46.6235 59.9772 46.1616 59.9772 44.8483C59.9772 43.6793 60.9009 43.0443 62.1132 43.0443C62.9791 43.0443 63.4843 43.1886 64.0471 43.5061L63.6719 44.1988C63.2245 43.9535 62.7482 43.8092 62.0987 43.8092C61.3338 43.8092 60.8143 44.1122 60.8143 44.8339C60.8143 45.5988 61.3194 45.8441 62.373 46.2194C63.3832 46.5657 64.4512 47.0131 64.4512 48.4275C64.4512 49.6831 63.5564 50.4336 62.0843 50.4336C60.8864 50.4625 60.0638 50.0295 59.5875 49.6254Z"
      fill="#AAAAAA"
    />
    <path
      d="M64.9708 45.2235H65.8945C65.8945 45.2235 67.1934 49.5676 67.1934 49.5821L68.4057 45.2235H69.3005L67.5686 50.7655C67.2656 51.7181 66.7604 52.2521 65.7646 52.2521C65.5626 52.2521 65.3461 52.2232 65.144 52.1655L65.2595 51.516C65.3894 51.5304 65.5193 51.5737 65.6924 51.5737C66.2264 51.5737 66.6305 51.3284 66.8326 50.6934L64.9708 45.2235Z"
      fill="#AAAAAA"
    />
    <path
      d="M75.6941 45.1225C76.7332 45.1225 77.0074 46.0029 77.0074 46.8544V50.3759H76.0982V46.8544C76.0982 46.3492 76.0116 45.8874 75.391 45.8874C74.9725 45.8874 74.525 46.1472 74.0776 46.5946V50.3759H73.1684V46.8544C73.1684 46.3492 73.0818 45.8874 72.4612 45.8874C72.0283 45.8874 71.5664 46.1905 71.1479 46.609V50.3759H70.2386V45.2235H70.7726L71.0036 46.0606C71.4798 45.541 72.0571 45.1225 72.7499 45.1225C73.4426 45.1225 73.7313 45.4977 73.9333 46.0173C74.4096 45.4833 75.0302 45.1225 75.6941 45.1225Z"
      fill="#AAAAAA"
    />
    <path
      d="M81.2072 47.7203C80.1825 47.9079 79.5186 48.1244 79.2588 48.4419C79.1289 48.6007 79.1001 48.745 79.1001 48.9615C79.1001 49.4666 79.3454 49.7553 79.8938 49.7553C80.4134 49.7553 80.8752 49.4955 81.2072 49.2501V47.7203ZM81.2072 46.8977C81.2072 46.2049 80.9907 45.8585 80.2402 45.8585C79.7207 45.8585 79.2588 46.0606 78.7681 46.306L78.4362 45.6998C78.9702 45.3678 79.5475 45.1225 80.2979 45.1225C81.5824 45.1225 82.0876 45.8152 82.0876 46.9698V50.3903H81.568L81.3371 49.7697C80.9763 50.1305 80.4134 50.4625 79.7495 50.4625C78.8403 50.4625 78.1764 49.8996 78.1764 49.0337C78.1764 48.4708 78.3929 48.0667 78.8691 47.778C79.331 47.4894 80.1536 47.2873 81.2072 47.1286V46.8977Z"
      fill="#AAAAAA"
    />
    <path
      d="M83.603 45.2235H84.137L84.3679 46.075C84.8441 45.541 85.4503 45.1225 86.2152 45.1225C87.3265 45.1225 87.6152 46.0029 87.6152 46.8544V50.3759H86.7059V46.8544C86.7059 46.3492 86.576 45.8874 85.9121 45.8874C85.4359 45.8874 84.9307 46.1905 84.5122 46.6235V50.3759H83.603V45.2235Z"
      fill="#AAAAAA"
    />
    <path
      d="M88.5821 45.8585V45.2235H89.3759L89.6068 43.7514H90.2562V45.2235H91.4108V45.8585H90.2562V49.0336C90.2562 49.5099 90.3428 49.7697 90.8335 49.7697C91.0356 49.7697 91.1943 49.7264 91.4253 49.6687L91.5551 50.2892C91.3531 50.3758 91.0356 50.448 90.7181 50.448C89.78 50.448 89.3614 50.0006 89.3614 49.0769V45.8441H88.5821V45.8585Z"
      fill="#AAAAAA"
    />
    <path
      d="M95.4519 47.4461C95.423 46.3637 95.0622 45.8008 94.3406 45.8008C93.6911 45.8008 93.2581 46.3059 93.186 47.4461H95.4519ZM94.4705 49.7408C95.0622 49.7408 95.4374 49.5099 95.8704 49.2357L96.2456 49.7841C95.7405 50.1882 95.2787 50.4625 94.3839 50.4625C93.0561 50.4625 92.2046 49.4378 92.2046 47.8069C92.2046 46.2194 93.0272 45.1225 94.3117 45.1225C95.6395 45.1225 96.3467 46.1183 96.3467 47.8358V48.0523H93.1427C93.2149 49.1491 93.6045 49.7408 94.4705 49.7408Z"
      fill="#AAAAAA"
    />
    <path
      d="M97.1838 47.8358C97.1838 46.508 97.7755 45.1225 99.3631 45.1225C100.027 45.1225 100.518 45.2813 100.979 45.6709L100.59 46.2771C100.258 46.0317 99.8682 45.8585 99.3775 45.8585C98.2951 45.8585 98.1363 46.9698 98.1363 47.8502C98.1363 48.7017 98.3239 49.7408 99.4064 49.7408C99.9259 49.7408 100.287 49.5388 100.633 49.2501L101.066 49.7841C100.691 50.1594 100.171 50.4769 99.3631 50.4769C97.7899 50.4625 97.1838 49.1203 97.1838 47.8358Z"
      fill="#AAAAAA"
    />
    <path
      d="M28.0385 28.4099C25.2963 28.4099 22.713 29.4779 20.7646 31.4263C18.8162 33.3746 17.7482 35.958 17.7482 38.7002C17.7482 41.4423 18.8162 44.0257 20.7646 45.9741C22.713 47.9224 25.2963 48.9904 28.0385 48.9904C30.7806 48.9904 33.364 47.9224 35.3124 45.9741C37.2463 44.0257 38.3143 41.4423 38.3287 38.7002C38.3287 35.958 37.2607 33.3746 35.3124 31.4263C33.364 29.4923 30.7806 28.4099 28.0385 28.4099ZM28.0385 53.7387C19.7543 53.7387 13 46.9843 13 38.7002C13 30.4016 19.7543 23.6617 28.0385 23.6617C36.3226 23.6617 43.077 30.4016 43.077 38.7002C43.077 46.9843 36.3226 53.7387 28.0385 53.7387Z"
      fill="#999999"
    />
    <path d="M45.5882 21.338H44.4192V22.507H45.5882V21.338Z" fill="#999999" />
    <path d="M45.5882 19H44.4192V20.169H45.5882V19Z" fill="#999999" />
    <path d="M42.2255 22.5071H41.1286V23.6472H42.2255V22.5071Z" fill="white" />
    <path
      d="M40.0606 24.7585H38.9493V25.8553H40.0606V24.7585Z"
      fill="#555555"
    />
    <path d="M43.4377 23.6472H42.211V24.744H43.4377V23.6472Z" fill="#555555" />
    <path d="M38.9493 23.705H37.8524V24.7585H38.9493V23.705Z" fill="#555555" />
    <path
      d="M42.2255 24.7585H41.1286V25.8553H42.2255V24.7585Z"
      fill="#555555"
    />
    <path
      d="M44.4192 20.1835H43.2646V21.3525H44.4192V20.1835Z"
      fill="#555555"
    />
    <path d="M43.2646 21.338H41.1286V22.507H43.2646V21.338Z" fill="#555555" />
    <path
      d="M41.1286 22.5071H40.0606V24.7585H41.1286V22.5071Z"
      fill="#555555"
    />
    <path
      d="M40.0606 25.8553V26.9522H38.9493V25.8553H37.1164V26.6491H36.135V27.6305H37.2319V28.7274H36.135V27.6305H34.966V29.348H33.8692V30.4448H33.1331V31.5417H32.3249C31.4157 32.8839 29.1642 37.0693 28.4715 38.7723C26.0035 35.3374 24.084 33.9807 22.5831 33.49C21.7749 33.2447 20.7935 34.1251 22.1357 35.525C25.2386 38.9455 26.22 41.7598 27.1581 44.0256C27.6633 45.2091 29.9003 45.3967 30.3477 44.1844C31.3002 41.6299 32.6857 38.7434 34.331 36.3909V35.1642H35.2114V34.0673H36.135V32.7829H37.2319V31.5561H36.1206V30.4304H37.2319V31.5561H38.3143V30.4448H39.1803V29.1026H40.1617V28.179H41.0998V25.8553H40.0606Z"
      fill="#555555"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style></style>
