<template>
  <div
    :class="[
      'footer__settings',
      'section',
      { 'footer__settings--flex-start': flexStart },
    ]"
  >
    <drop-down
      class="footer__settings--currency-switcher"
      :active-item="currencyStructure(currency)"
      :items="availableCurrencies"
      open-position="top"
      has-icon
      :loading="isLoadingNewCurrency"
      @action="currencyHandler"
    />

    <drop-down
      :active-item="localeStructure(cookieLocale)"
      :items="availableLocales"
      open-position="top"
      has-image-icon
      :loading="isLoadingNewLocale"
      @action="localeHandler"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DropDown from '~/components/DropDown.vue'
import { LOCALE_FLAG_STRING, LOCALE_NAME, CURRENCY_SYMBOL } from '~/config'

export default {
  name: 'FooterSettings',
  components: { DropDown },
  props: {
    flexStart: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoadingNewCurrency: false,
      isLoadingNewLocale: false,
    }
  },
  computed: {
    ...mapState(['storeCode', 'siteStore', 'currency', 'locale']),
    cookieLocale() {
      return this.$localeFromCookie ? this.$locale : this.locale
    },
    availableCurrencies() {
      return this.siteStore.available_currencies.map((availableCurrency) =>
        this.currencyStructure(availableCurrency)
      )
    },
    availableLocales() {
      return this.siteStore.available_locales.map((availableLocale) =>
        this.localeStructure(availableLocale)
      )
    },
  },
  methods: {
    currencyStructure(availableCurrency) {
      return {
        title: availableCurrency,
        icon: CURRENCY_SYMBOL[availableCurrency],
        code: availableCurrency,
      }
    },
    localeStructure(availableLocale) {
      const flagString = LOCALE_FLAG_STRING[availableLocale]
      return {
        title: LOCALE_NAME[availableLocale],
        icon: require(`~/assets/images/icons/icons-regular/${flagString}.png`),
        code: availableLocale,
      }
    },
    async localeHandler(item) {
      this.isLoadingNewLocale = true
      await this.$axios.$get('/locale/set', {
        params: {
          ...this.$store.getters.storeInfo,
          new_locale: item.code,
        },
      })
      await this.$store.dispatch('fetchSiteStore', {
        code: this.storeCode,
        locale: item.code,
        currency: this.currency,
      })
      this.setNewLang(item.code)
      this.isLoadingNewLocale = false
      this.endSwitching('locale')
    },
    setNewLang(lang) {
      const messages = require(`~/locales/${lang}.json`)
      this.$i18n.setLocaleMessage(lang, messages)
      this.$i18n.locale = lang
    },
    endSwitching(type) {
      this.$displayTopMessage(this.$t(`${type}_changed`))
      setTimeout(() => {
        window.location.reload(true)
      }, 1500)
    },
    async currencyHandler(item) {
      const lastCurrency =
        sessionStorage.getItem('v4_last_currency') || this.$currency

      if (lastCurrency === item.code) return

      try {
        this.isLoadingNewCurrency = true
        await this.$axios.$get('/currency/set', {
          params: {
            ...this.$store.getters.storeInfo,
            currency: lastCurrency,
            new_currency: item.code,
          },
        })
        await this.$store.dispatch('fetchSiteStore', {
          code: this.storeCode,
          locale: this.locale,
          currency: item.code,
        })
        this.isLoadingNewCurrency = false
        sessionStorage.setItem('v4_last_currency', item.code)
        this.endSwitching('currency')
      } catch (err) {
        this.$displayTopMessage(this.$t('action_error'), { type: 'error' })
        this.$sentry.captureException(err.response || err)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.footer__settings {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer__settings--flex-start {
  justify-content: flex-start;
}

.footer__settings--currency-switcher {
  display: inline-block;
  margin-right: 5px;
}

.footer__settings--currency-switcher select {
  width: 200px;
  margin-bottom: 18px;
}
</style>
