<template>
  <svg
    width="55"
    height="35"
    viewBox="0 0 55 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M51 34.3H3.99998C2.07613 34.3 0.499985 32.7239 0.499985 30.8V4C0.499985 2.07614 2.07613 0.5 3.99998 0.5H51C52.9238 0.5 54.5 2.07614 54.5 4V30.9C54.5 32.7157 52.9321 34.3 51 34.3Z"
        fill="white"
        stroke="#F5F6F5"
      />
      <path
        d="M16.3232 7.33606V27.8722H28.2381C36.0978 27.8722 39.5062 23.4492 39.5062 17.584C39.5062 11.7416 36.0978 7.33606 28.2381 7.33606H16.3232Z"
        fill="white"
      />
      <path
        d="M17.6109 8.63135H28.2396C35.4536 8.63135 38.2188 12.59 38.2188 17.5837C38.2188 23.5717 34.3574 26.5763 28.2396 26.5763H17.6109V8.63135ZM18.6413 9.66675V25.5415H28.2396C34.0345 25.5415 37.1875 22.8281 37.1875 17.5837C37.1875 12.194 33.7696 9.66643 28.2396 9.66643H18.6413V9.66675Z"
        fill="#555555"
      />
      <path
        d="M19.9291 18.7511H23.0017V24.2462H19.9291V18.7511Z"
        fill="#555555"
      />
      <path
        d="M21.4651 17.9012C22.5197 17.9012 23.3747 17.0421 23.3747 15.9823C23.3747 14.9226 22.5197 14.0635 21.4651 14.0635C20.4104 14.0635 19.5555 14.9226 19.5555 15.9823C19.5555 17.0421 20.4104 17.9012 21.4651 17.9012Z"
        fill="#555555"
      />
      <path
        d="M29.4452 16.88V17.7698H27.2559V14.1992H29.3752V15.0887H28.141V15.5021H29.3081V16.3919H28.141V16.8803L29.4452 16.88ZM29.8282 17.7704L30.8996 14.1979H32.159L33.23 17.7704H32.3086L32.1076 17.0794H30.9497L30.7484 17.7704H29.8282ZM31.2092 16.1906H31.85L31.556 15.1814H31.5025L31.2092 16.1906ZM33.6751 14.1983H34.5609V16.8803H35.872C35.5122 12.0138 31.7032 10.9623 28.24 10.9623H24.549V14.2002H25.0952C26.0913 14.2002 26.7096 14.8788 26.7096 15.9709C26.7096 17.0978 26.1061 17.7704 25.0952 17.7704H24.549V24.2487H28.2403C33.8682 24.2487 35.8282 21.622 35.8975 17.7698H33.6751V14.1983ZM24.5484 15.0896V16.8803H25.0952C25.4742 16.8803 25.8245 16.7701 25.8245 15.9706C25.8245 15.1897 25.4348 15.0896 25.0952 15.0896H24.5484Z"
        fill="#555555"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="55" height="35" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {}
</script>

<style></style>
